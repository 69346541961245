import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { Tabs, Tab, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const AFKGuidesTeamsPVP: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Arena Teams</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_meta.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Arena Teams</h1>
          <h2>
            Best teams to use in AFK Journey Arena/PVP mode - both on defense
            and offense.
          </h2>
          <p>
            Last updated: <strong>14/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Introduction" />
        <p>
          In the AFK Journey arena, the key to success is to know your enemy,
          and to tailor your team towards countering each player in your local
          bracket, rather than using the same team for every purpose. In this
          guide, we’ll go over some meta Arena team archetypes, and which maps
          to use them in. This guide will be updated as new characters are
          released and the pvp meta evolves.
        </p>
        <p>Check out our Supreme Arena Guide here!</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Best Supreme Arena Teams"
            link="/afk-journey/guides/supreme-arena-teams"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_sarena.webp"
                alt="Best Supreme Arena Teams"
              />
            }
          />
        </Row>
        <p>Now let us take a look at some of the popular Team Archetypes!</p>
        <SectionHeader title="Harak Hypercarry" />
        <div className="faction-div Hypogean">
          <div className="heroes with-margin">
            <div className="employees-container afk-cards">
              <AFKCharacter
                mode="card"
                slug="harak"
                showLabel
                showTags
                showIcon
                enablePopover
              />
            </div>
          </div>
          <div className="info">
            <p>
              A team that supports Harak to build him into an unstoppable force.
              Attack focused.
            </p>
            <ul className="bigger-margin">
              <li>
                <strong>Bring Harak</strong>:{' '}
                <AFKCharacter mode="inline" slug="harak" enablePopover /> is the
                main carry, and with his heal denial skill, he can help lock
                down enemy units to get the first kill and start snowballing out
                of control.
              </li>
              <li>
                <strong>Pick 2 Supports</strong>: We typically want one
                fast-acting support, such as{' '}
                <AFKCharacter mode="inline" slug="damian" enablePopover /> or{' '}
                <AFKCharacter mode="inline" slug="ludovic" enablePopover /> and
                a slow but powerful support such as{' '}
                <AFKCharacter mode="inline" slug="hewynn" enablePopover /> or{' '}
                <AFKCharacter mode="inline" slug="koko" enablePopover /> to
                complement each other.
              </li>
              <li>
                <strong>Pick a Tank</strong>: We need someone to hold the line
                long enough for Harak to get his first kill.{' '}
                <AFKCharacter mode="inline" slug="phraesto" enablePopover />{' '}
                works best with Harak’s friendly-fire gimmick, and also provides
                energy regen, but any tank will do in a pinch.
              </li>
              <li>
                <strong>Pick a DPS</strong>: We need a powerful secondary carry
                to help Harak secure his first kill, that can be{' '}
                <AFKCharacter mode="inline" slug="lily-may" enablePopover />{' '}
                <AFKCharacter mode="inline" slug="vala" enablePopover />
                <AFKCharacter mode="inline" slug="silvina" enablePopover /> and
                so on - anyone who can secure kills.
              </li>
            </ul>
            <p>
              This team is very hard to stop once it gets going, and it works in
              most maps. Requires high investment on Harak for best results.
            </p>
            <p>
              Not as strong on defense as there are many counters and ways to
              take out Harak before he can do his thing if the attacker is
              prepared.
            </p>
          </div>
        </div>
        <SectionHeader title="Eironn Nuke Team" />
        <div className="faction-div Wilder">
          <div className="heroes with-margin">
            <div className="employees-container afk-cards">
              <AFKCharacter
                mode="card"
                slug="eironn"
                showLabel
                showTags
                showIcon
                enablePopover
              />
            </div>
          </div>
          <div className="info">
            <p>
              In this team, your strategy is to kill the enemy before they kill
              you, through overwhelming burst damage potential.
            </p>
            <ul className="bigger-margin">
              <li>
                <strong>Bring Eironn</strong>: He is the character who comes to
                mind when we think about who enables the very idea of a “Nuke
                Team”. But only strictly necessary in the{' '}
                <AFKCharacter mode="inline" slug="eironn" enablePopover />{' '}
                variant.
              </li>
              <li>
                <strong>Pick 2+ DPS</strong>: Pick characters with high burst
                damage potential, and who benefit from crowd control synergy.
              </li>
              <li>
                <strong>Pick a Tank. Or don’t</strong>: This role is optional,
                Eironn can be used as a Tank thanks to his high Dodge Rate
                allowing him to avoid damage enough that you can bring more DPS
                carries. Of particular interest in the Tank role, is{' '}
                <AFKCharacter mode="inline" slug="ulmus" enablePopover />, as he
                enables powerful synergy with Eironn’s crowd control, as long as
                Eironn’s ultimate is not interrupted
              </li>
              <li>
                <strong>Pick a Support. Or don’</strong>: This role is optional,
                if running a more aggressive variant, we can ditch our support
                role entirely, but this is risky, because if the enemy manages
                to survive the initial burst and stabilize through healing,
                shields, and damage reduction, you won’t have a way to recover.
              </li>
              <li>
                <strong>Pick a Celestial/Hypogean. Or don’t</strong>: This role
                is optional. A Celestial/Hypogean character (or CH, as we’ll
                start calling them for brevity.) can help deal more damage, or
                offer sustain. In the late game we should have at least one of
                these to work with so might be worth slotting them into the
                team.
              </li>
            </ul>
            <p>
              This used to be the main meta team in late-game arena, until{' '}
              <AFKCharacter mode="inline" slug="lily-may" enablePopover /> came
              around. With Lily May being able to counter Eironn’s entire game
              plan, this team is not quite as prevalent as it once was.
            </p>
            <p>
              But if the enemy doesn’t have Lily May, this is still one of the
              best teams to use.
            </p>
          </div>
        </div>
        <SectionHeader title="Dionel Hypercarry" />
        <div className="faction-div Celestial">
          <div className="heroes with-margin">
            <div className="employees-container afk-cards">
              <AFKCharacter
                mode="card"
                slug="dionel"
                showLabel
                showTags
                showIcon
                enablePopover
              />
            </div>
          </div>
          <div className="info">
            <p>
              This is technically another type of nuke team, if your{' '}
              <AFKCharacter mode="inline" slug="dionel" enablePopover /> is
              invested enough, that is.
            </p>
            <ul className="bigger-margin">
              <li>
                <strong>Bring Dionel</strong>:{' '}
                <AFKCharacter mode="inline" slug="dionel" enablePopover /> is
                best used as a build-around unit, being the most straightforward
                of the Celehypos, he just deals damage, so we’ll focus on
                amplifying that even further.
              </li>
              <li>
                <strong>Pick 2 Supports</strong>: More specifically, “buffers”.
                We want two support characters offering different Stat-boosting
                buffs to feed Dionel’s Intoxication stacks. Typically that means
                ATK, Haste and ATK SPD, but all stat-boosting buffs work.
              </li>
              <li>
                <strong>Pick 2 from Tank/DPS/CH</strong>: This is our flex slot,
                we can bring pretty much anyone, either to increase the team’s
                damage output, or survivability.
              </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="dunlingr" enablePopover />{' '}
                  in is the ideal Celestial partner for Dionel, as he allows
                  Dionel to still do his thing while also hindering the enemy
                  team. But he is otherwise a niche hero and does not see much
                  use outside this particular team so think twice before going
                  all in on Dunlingr for Dionel.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="harak" enablePopover /> is
                  sometimes paired with Dionel as well in whale teams as Dionel
                  helps him secure kills and snowball out of control, but it
                  quickly becomes a very expensive team to invest in at this
                  rate.
                </li>
              </ul>
            </ul>
            <p>
              Some of the highest burst damage potential in the game. This team
              is sometimes used by whales who had the spare resources to invest
              in Dionel rather than the more utility-focused Celehypos. Requires
              very high investment up to Paragon tiers on Dionel to be
              competitive with other meta teams.
            </p>
            <p>
              Not as strong on defense against near-peer attackers, Dionel’s
              position at the back of the team also makes him especially
              vulnerable to assassin teams.
            </p>
          </div>
        </div>
        <SectionHeader title="Heal Stall Team" />
        <div className="faction-div Celestial">
          <div className="heroes with-margin">
            <div className="employees-container afk-cards">
              <AFKCharacter
                mode="card"
                slug="scarlita"
                showLabel
                showTags
                showIcon
                enablePopover
              />
            </div>
          </div>
          <div className="info">
            <p>
              A powerful stall team that can also work on attack. Defense
              focused.
            </p>
            <ul className="bigger-margin">
              <li>
                <strong>Bring Scarlita or DPS</strong>:{' '}
                <AFKCharacter mode="inline" slug="scarlita" enablePopover />{' '}
                ends up being the main source of damage on this team, as well as
                making the frontline tankier, and she is what allows the team to
                get kills at all by using her instakill mechanic. But you can
                also just run your best dps carry in this slot.
              </li>
              <li>
                <strong>Pick a Tank</strong>: This is a stall team so bringing a
                tank goes without saying.
              </li>
              <li>
                <strong>Pick 2 Supports</strong>: We want our supports to
                provide a constant flow of healing to keep the team going as
                long as possible.
              </li>
              <li>
                <strong>Pick a DPS</strong>: It is advised to bring a strong
                damage carry such as{' '}
                <AFKCharacter mode="inline" slug="lily-may" enablePopover />, as
                the days of pure stall teams are long gone.
              </li>
            </ul>
            <p>
              This team is geared towards a defensive gameplan. Ideally, we want
              to survive the enemy’s first burst of damage and then take
              advantage of superior sustain to win in the end
            </p>
            <p>
              While this team still works depending on the server arena meta,
              with increasingly powerful units being released, it has been
              largely superseded by the following{' '}
              <AFKCharacter mode="inline" slug="talene" enablePopover /> variant
              and is widely considered to be a Legacy team.
            </p>
          </div>
        </div>
        <SectionHeader title="Talene/Tasi Revive Stall Team" />
        <div className="faction-div Hypogean">
          <div className="heroes with-margin">
            <div className="employees-container afk-cards">
              <AFKCharacter
                mode="card"
                slug="talene"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="tasi"
                showLabel
                showTags
                showIcon
                enablePopover
              />
            </div>
          </div>
          <div className="info">
            <p>
              A team designed to survive against overwhelming burst damage, then
              strike back with a deadly counterattack.
            </p>
            <ul className="bigger-margin">
              <li>
                <strong>Bring Talene</strong>:{' '}
                <AFKCharacter mode="inline" slug="talene" enablePopover /> is a
                powerful damage dealer, enabled by healing, who can come back
                from the dead and deal damage in a large area, and is one of the
                main carries of this team.
              </li>
              <li>
                <strong>Bring Tasi</strong>:{' '}
                <AFKCharacter mode="inline" slug="tasi" enablePopover /> is
                another character who can revive after dying and can often solo
                the entire enemy team even after her own team has perished.
                Greatly benefits from energy regen.
              </li>
              <li>
                <strong>Pick a Support (Optional)</strong>: Any support will do,
                but following the revival theme,{' '}
                <AFKCharacter mode="inline" slug="damian" enablePopover /> tends
                to be the best pick as he can just summon another cart if his
                first cart is destroyed, enabling Talene and Tasi to come back
                stronger than ever. But this role is optional, both characters
                can survive on their own with some luck, and some players drop
                the support slot entirely in order to bring more DPS carries.
              </li>
              <li>
                <strong>Pick a Tank</strong>: We may want a Tank in order to buy
                some time for the rest of the team to do their thing.{' '}
                <AFKCharacter mode="inline" slug="phraesto" enablePopover /> in
                particular can be a powerful choice thanks to his energy regen
                buffs, and ability to summon a new close at EX +15.{' '}
                <AFKCharacter mode="inline" slug="thoran" enablePopover /> also
                works as he can revive after an initial burst. Another option is{' '}
                <AFKCharacter mode="inline" slug="satrana" enablePopover />, as
                she does very well against the Magic DMG meta.
              </li>
              <li>
                <strong>Pick a DPS</strong>: Bring your best DPS carry in this
                slot, or one that is useful against a specific enemy defense.
              </li>
            </ul>
            <p>
              This team is notorious for often surviving against the burst
              damage of{' '}
              <AFKCharacter mode="inline" slug="dionel" enablePopover /> or{' '}
              <AFKCharacter mode="inline" slug="eironn" enablePopover /> teams,
              and then killing them after Talene and Tasi come back from the
              dead, supported by Damian’s healing cart.
            </p>
            <p>
              This immortality can still be countered by{' '}
              <AFKCharacter mode="inline" slug="scarlita" enablePopover />, and
              does not fare well in the mirror match due to ultimate order
              favouring the attacker.
            </p>
          </div>
        </div>
        <SectionHeader title="Mauler Rush" />
        <div className="faction-div Mauler">
          <div className="heroes with-margin">
            <div className="employees-container afk-cards">
              <AFKCharacter
                mode="card"
                slug="shakir"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="lenya"
                showLabel
                showTags
                showIcon
                enablePopover
              />
            </div>
          </div>
          <div className="info">
            <p>
              A dive-type team that gets all up in the enemy’s business. Attack
              oriented.
            </p>
            <ul className="bigger-margin">
              <li>
                <strong>Bring Shakir or Lenya</strong>:{' '}
                <AFKCharacter mode="inline" slug="shakir" enablePopover /> is
                the biggest enabler of mauler melee teams, being tanky enough to
                fight in the front lines as long as he can stay alive long
                enough to pop his ult, and providing powerful buffs to the team.{' '}
                <AFKCharacter mode="inline" slug="lenya" enablePopover /> works
                particularly well in open maps and favours CC variants of this
                team along with{' '}
                <AFKCharacter mode="inline" slug="alsa" enablePopover />.
              </li>
              <li>
                <strong>Pick 2 DPS</strong>: Preferably mauler faction heroes,
                but you can experiment with melee characters from other factions
                too. Alsa can help enable control variants of this team as well.
              </li>
              <li>
                <strong>Pick a Tank</strong>: Mauler has resilient tanks who can
                keep the rest of the team alive long enough for Shakir to get
                his ult out, but you can also experiment with other faction
                tanks.{' '}
                <AFKCharacter mode="inline" slug="satrana" enablePopover /> is
                not a tank but can function much like one in a magic damage
                oriented meta.
              </li>
              <li>
                <strong>Pick a Support or Specialist</strong>: Someone to keep
                this team going just long enough to rush the enemy. You don’t
                necessarily need a dedicated healer, as some Specialists can
                also provide shields or healing.{' '}
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> can
                be very useful to throw a mauler into the enemy backline and
                take out healers or a damage carry as soon as possible.
              </li>
            </ul>
            <p>
              This team can be very resilient, while also dishing out CC and
              damage. F2P friendly. Works best at the start of a new server
              where players don’t have many Celestial/Hypogean characters, or in
              Supreme Arena. Not as strong in the late game regular arena.
            </p>
          </div>
        </div>
        <SectionHeader title="Assassin Team" />
        <div className="faction-div Lightbearer">
          <div className="heroes with-margin">
            <div className="employees-container afk-cards">
              <AFKCharacter
                mode="card"
                slug="vala"
                showLabel
                showTags
                showIcon
                enablePopover
              />
              <AFKCharacter
                mode="card"
                slug="silvina"
                showLabel
                showTags
                showIcon
                enablePopover
              />
            </div>
          </div>
          <div className="info">
            <p>
              A team geared towards fighting other burst teams by taking out
              priority targets before they can pose a thread. Attack Oriented.
            </p>
            <ul className="bigger-margin">
              <li>
                <strong>Bring Vala</strong>:{' '}
                <AFKCharacter mode="inline" slug="vala" enablePopover /> is a
                powerful assassin who can target one backline enemy and keep
                them from using their ultimate by removing their energy with her
                attacks, and is a staple in any assassin team.
              </li>
              <li>
                <strong>Bring Silvina</strong>:{' '}
                <AFKCharacter mode="inline" slug="silvina" enablePopover /> is
                often used as part of an inseparable duo with Vala, as these two
                combined can quickly take out the enemy backline, especially if
                they get to target the same enemy.
              </li>
              <li>
                <strong>Pick 2+ DPS</strong>: As this is an Assassin Team that
                focuses entirely on damage, we can afford to bring all our best
                damage carries to take out the enemy as quickly as possible and
                add redundancy against enemy defenses.{' '}
                <AFKCharacter mode="inline" slug="lily-may" enablePopover /> and{' '}
                <AFKCharacter mode="inline" slug="bonnie" enablePopover /> are
                among the best choices, but any DPS will do.
              </li>
              <li>
                <strong>Pick a Support and/or Tank(Optional)</strong>: One can
                bring a Support or Tank if we believe the enemy may still pose a
                threat after the initial burst and want a chance to recover, or
                to buy enough time to finish the job.
              </li>
            </ul>
            <p>
              This team is often the best against teams that rely on a powerful
              main carry, such as{' '}
              <AFKCharacter mode="inline" slug="dionel" enablePopover /> or{' '}
              <AFKCharacter mode="inline" slug="harak" enablePopover />, as it
              enables killing the enemy carry before they can do their thing.
            </p>
          </div>
        </div>
        <SectionHeader title="Defense Examples / Meta Snapshot" />
        <p>
          Here we’ll showcase some popular defense teams using a mix of the
          above archetypes. Arena 3 and Arena 5 - Special are the most popular
          maps for defense, the former due to being immune to Reinier
          manipulation, and the latter due to energy tiles.
        </p>
        <h5>ARENA 3</h5>
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs-season"
          className="nav-tabs"
        >
          <Tab eventKey="Team1" title={<>Team #1</>} className="with-bg">
            <h5>Team #1 - HARAK HYPERCARRY</h5>
            <StaticImage
              src="../../../images/afk/dream_s/arena_1.webp"
              alt="Guides"
            />
            <p>
              <strong>Comments</strong>:{' '}
              <AFKCharacter mode="inline" slug="thoran" enablePopover /> can
              also be replaced by
              <AFKCharacter mode="inline" slug="phraesto" enablePopover />,
              though that does make one of our units vulnerable to
              <AFKCharacter mode="inline" slug="reinier" enablePopover />.
            </p>
          </Tab>
          <Tab eventKey="Team2" title={<>Team #2</>} className="with-bg">
            <h5>Team #2 - DIONEL HYPERCARRY</h5>
            <StaticImage
              src="../../../images/afk/dream_s/arena_2.webp"
              alt="Guides"
            />
            <p>
              <strong>Comments</strong>: While this version has{' '}
              <AFKCharacter mode="inline" slug="harak" enablePopover />, any
              high threat DPS carry will work.
            </p>
          </Tab>
          <Tab eventKey="Team3" title={<>Team #3</>} className="with-bg">
            <h5>Team #3 - EIRONN NUKE TEAM</h5>
            <StaticImage
              src="../../../images/afk/dream_s/arena_3.webp"
              alt="Guides"
            />
            <p>
              <strong>Comments</strong>: This aggressive variant is best used in
              attack teams.{' '}
              <AFKCharacter mode="inline" slug="parisa" enablePopover /> can be
              replaced by a support character, or a tank, if moved to the
              frontline.
            </p>
          </Tab>
          <Tab eventKey="Team4" title={<>Team #4</>} className="with-bg">
            <h5>Team #4 - HEAL STALL TEAM</h5>
            <StaticImage
              src="../../../images/afk/dream_s/arena_4.webp"
              alt="Guides"
            />
            <p>
              <strong>Comments</strong>: Different supports can be used, at the
              cost of faction bonuses.
            </p>
          </Tab>
          <Tab eventKey="Team5" title={<>Team #5</>} className="with-bg">
            <h5>Team #5 - TALENE REVIVE TEAM</h5>
            <StaticImage
              src="../../../images/afk/dream_s/arena_5.webp"
              alt="Guides"
            />
            <p>
              <strong>Comments</strong>:{' '}
              <AFKCharacter mode="inline" slug="tasi" enablePopover /> does not
              work as well in this map due to the lack of energy tiles, so this
              variant ends up being better in a magic damage meta, especially
              for defense teams.
            </p>
          </Tab>
        </Tabs>
        <br />
        <h5>ARENA 5</h5>
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs-season"
          className="nav-tabs"
        >
          <Tab eventKey="Team1" title={<>Team #1</>} className="with-bg">
            <h5>Team #1 - HARAK HYPERCARRY</h5>
            <StaticImage
              src="../../../images/afk/dream_s/arena_6.webp"
              alt="Guides"
            />
            <p>
              <strong>Comments</strong>: Energy tiles enable a more aggressive
              melee team-backed team.
            </p>
          </Tab>
          <Tab eventKey="Team2" title={<>Team #2</>} className="with-bg">
            <h5>Team #2 - EIRONN NUKE TEAM</h5>
            <StaticImage
              src="../../../images/afk/dream_s/arena_7.webp"
              alt="Guides"
            />
            <p>
              <strong>Comments</strong>: Can be changed to include more burst
              damage
            </p>
          </Tab>
          <Tab eventKey="Team3" title={<>Team #3</>} className="with-bg">
            <h5>Team #3 - TALENE TASI TEAM</h5>
            <StaticImage
              src="../../../images/afk/dream_s/arena_8.webp"
              alt="Guides"
            />
            <p>
              <strong>Comments</strong>: The slot occupied by{' '}
              <AFKCharacter mode="inline" slug="lily-may" enablePopover /> is a
              flex slot, and there are other options that work in her place,
              such as
              <AFKCharacter
                mode="inline"
                slug="scarlita"
                enablePopover
              /> or <AFKCharacter mode="inline" slug="bonnie" enablePopover />,
              or an additional support. Tasi gets an instant ultimate on this
              map.
            </p>
          </Tab>
          <Tab eventKey="Team4" title={<>Team #4</>} className="with-bg">
            <h5>Team #4 - TALENE TASI TEAM (No Phraesto)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/arena_9.webp"
              alt="Guides"
            />
            <p>
              <strong>Comments</strong>: This variant also works well as both
              <AFKCharacter mode="inline" slug="tasi" enablePopover /> and{' '}
              <AFKCharacter mode="inline" slug="hewynn" enablePopover /> get
              their ultimates right away, and even if the enemy has{' '}
              <AFKCharacter mode="inline" slug="lily-may" enablePopover />, she
              can only cancel one of them.
            </p>
          </Tab>
        </Tabs>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesTeamsPVP;

export const Head: React.FC = () => (
  <Seo
    title="Arena Teams | AFK Journey | Prydwen Institute"
    description="Best teams to use in AFK Journey Arena/PVP mode - both on defense and offense."
    game="afk"
  />
);
